import { api } from './base';

export default {
  find(id, uuid) {
    return api.get('transactions/' + id, {}, uuid);
  },
  createPaynow(transactionId) {
    return api.post('transactions/' + transactionId + '/paynow')
  }
}
