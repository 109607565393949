<template>
  <section class="section vh100">
    <Spinner v-if="isLoading" />

    <div v-if="!isLoading">
      <div v-if="!payment">
        <BaseErrors :errors="['Not authorized']" />
      </div>
      <div v-if="payment">
        <div v-if="payment.status == 'pending'">
          <Spinner title="Płatność w toku" subtitle="Strona odświezy się automatycznie..." />
        </div>
        <div v-else-if="payment.status == 'success'">
          <h1 class="title">Dziękujemy!</h1>
          <h2 class="subtitle mt-2">Twoja płatność powiodła się.<br />Mozesz teraz zobaczyć nowy wynik losowania</h2>
        </div>
        <div v-else>
          <BaseErrors :errors="['Twoja płatność nie została potwierdzona.']" />
          <h2 class="subtitle">Przejdź dalej, aby odczytać poprzedni wynik.</h2>
        </div>

        <ul class="options mt-4" v-if="redirectUrl">
          <li>
            <a
              :href="redirectUrl"
              class="button is-primary"
              style="width: 100%"
            >
              Przejdź dalej
            </a>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
import paymentApi from "../api/payment";
import { getUuidFromCookieByDrawId } from "../api/participant";
import BaseErrors from '@/components/BaseErrors';
import Spinner from '@/components/Spinner';

const POLLING_INTERVAL = 15 * 1000; // 15s
const POLLING_TIMEOUT = 10 * 60 * 1000; // 10min

export default {
  name: "Payment",
  components: { BaseErrors, Spinner },
  data() {
    return {
      isLoading: true,
      payment: null,
      redirectUrl: null,
      poll: null,
    };
  },
  methods: {
    fetchPaymentStatus() {
      const drawId = this.$route.params.drawId;

      return paymentApi
        .find(this.$route.params.id, getUuidFromCookieByDrawId(drawId))
        .then((data) => {
          if (!data.drawId) {
            return false;
          }

          this.payment = data;

          if (this.payment.status != "pending") {
            clearInterval(this.poll);
            this.redirectUrl = "/join/" + this.payment.drawId;
          }
        })
        .catch((err) => {})
        .finally(() => (this.isLoading = false));
    },
  },
  async created() {
    await this.fetchPaymentStatus();

    if (this.payment && this.payment.status === "pending") {
      this.poll = setInterval(this.fetchPaymentStatus, POLLING_INTERVAL);
      setTimeout(() => {
        clearInterval(this.poll);
      }, POLLING_TIMEOUT);
    }
  },
};
</script>

<style>
</style>
